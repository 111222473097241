import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect,Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { login, logout } from '../redux/action';
export default function Header(props) {
  const { setMenuExpand, menuExpand } = props;
  const dispatch = useDispatch()
  const history = useHistory()
  const LoginState = useSelector((state) => state.LoginState);

  return (
    <div className="row header-design"
    >
      <div className="col-md-6">
        <div className="bars-position"
          onClick={() => {
            setMenuExpand(!menuExpand)
          }}
        >
          <i className="fa fa-bars" style={{ fontSize: "20px" }} />
        </div>
      </div>
      <div className="col-md-6"

      >
        <div className="name-header-postion">
          <Link to={"/profile"} className="Link">
            {LoginState.userName}&nbsp;&nbsp;
          </Link>
          <i className="fa fa-sign-out" style={{ fontSize: "20px" }}
            onClick={() => {
              dispatch(logout())
              history.push('/')
            }}
          />
        </div>
      </div>
    </div>
  )
}