import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsGETApiWithToken, AdsPOSTApi, AdsPOSTApiWithToken, UserPlaceHolder, sideBarImage } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import SecondScreenComponent from '../ExamDetails/GetStartedPage';
import FinalScreenExam from '../ExamDetails/MarksObtainedPage';
export default function FirstScreenExam(props) {
  const {
    ExamData,
    setReload,
    choose, setChoose,
    AnswerArray, setAnswerArray,
    ResultData, setResultData,
  } = props;
  const LoginUser = useSelector((state) => state.LoginState);
  const history = useHistory();
  const location = useLocation();
  const [QuestionsData, setQuestionsData] = React.useState(JSON.parse(ExamData.meta.exam_question_block_text))
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(true);
  const [QuestionsEditData, setQuestionsEditData] = React.useState(JSON.parse(ExamData.meta.exam_question_block_text)["item-0"])
  const [PlaySingle, setPlaySingle] = React.useState(ExamData.meta.media_non_repeat);
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState("item-0");
  const [TimingStarted, setTimingStarted] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({})
  const [obtainedMarks, setObtainedMarks] = React.useState(0)
  const [mediaPlayedJSON, setMediaPlayed] = React.useState({})
  const [batchExpired, setBatchExpired] = React.useState({})
  const [Name, setName] = React.useState("")
  const [PhoneNumber, setPhoneNumer] = React.useState("")
  const token = localStorage.getItem('teacher_token');
  const [Start, setStart] = React.useState(false);

  useEffect(() => {
    var mediaPlayed = {};
    const ArrayValue = Object.values(JSON.parse(ExamData.meta.exam_question_block_text))
    for (let i = 0; i < ArrayValue.length; i++) {
      const QuestionJSON = ArrayValue[i]
      mediaPlayed = {
        ...mediaPlayed,
        [`question-${i + 1}`]: "0",
        [`answer-${i + 1}-a`]: "0",
        [`answer-${i + 1}-b`]: "0",
        [`answer-${i + 1}-c`]: "0",
        [`answer-${i + 1}-d`]: "0",
      }
      setMediaPlayed(mediaPlayed)
      if (AnswerArray.length < ArrayValue.length)
        AnswerArray.push({
          question_number: QuestionJSON.question_number,
          correct_answer: QuestionJSON.correct_answer,
          answerChoosen: "",
          solved: 0
        })
    }
    const studentName = localStorage.getItem('studentName');
    const userPhoneNumber = localStorage.getItem('studentPhone');;
    if (studentName !== null)
      setName(studentName)
    if (userPhoneNumber !== null)
      setPhoneNumer(userPhoneNumber)
  }, [])
  const SubmitExam = () => {
    setReload(true)
    // const token = store.getState().LoginState.token
    const id = store.getState().LoginState.userID;
    var correct = 0;
    const date = new Date();
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    const day = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`
    for (let i = 0; i < AnswerArray.length; i++) {
      if (AnswerArray[i].correct_answer == AnswerArray[i].answerChoosen)
        correct++
    }
    const data = {
      title: `Exam-${ExamData.title.rendered} User-${id} Date-${date.getUTCFullYear()}/${month}/${day}`,
      status: "publish",
      meta: {
        "exam_answers_array_results": JSON.stringify(AnswerArray),
        "exam_date_results": `${date.getUTCFullYear()}-${month}-${day}`,
        "exam_name_results": `${ExamData.title.rendered}`,
        "exam_id_results": `${ExamData.id}`,
        "exam_total_results": `${correct}`,
        "student_id_results": ``,
        "student_name_results": `${Name}`,
        "student_phone_number_results": `${PhoneNumber}`
      }
    }
    AdsPOSTApiWithToken(data, slugs.db_slug_paid_results, token)
      .then((res) => {
        setReload(false)
        setResultData(res.data)
        setChoose(2)
      }).catch((err) => {
        console.log(err)
        console.log(err.response.data)
      })
  }
  const fetchResult = () => {
    AdsGETApiWithToken({
      "filter[meta_query][1][key]": "exam_id_results",
      "filter[meta_query][1][value][0]": ExamData.id,
      "filter[meta_query][1][compare]": "=",
      "filter[meta_query][relation]": "AND",
      "filter[meta_query][2][key]": "student_phone_number_results",
      "filter[meta_query][2][value][0]": `${PhoneNumber}`,
      "filter[meta_query][2][compare]": "=",
    }, slugs.db_slug_paid_results, token)
      .then((response) => {
        if (response.data.length !== 0) {
          setChoose(2)
          setAnswerArray(JSON.parse(response.data[0].meta.exam_answers_array_results))
          setResultData(response.data[0])
          setStart(true)
        }
      })
      .finally(() => {
        setReload(false)
      })
  }

  return (
    <div style={{ border: "1px solid #aaa", padding: "0px 0 10px", position: "relative" }}>
      {!Start ?
        <div className='center'>
          <div style={{ padding: "15px" }}>
            <h2>{ExamData.title.rendered}</h2>
          </div>
          <img src='https://statics.teams.cdn.office.net/hashed/join-code-icon-4f716a8.svg'
            style={{ width: "100px", height: "100px" }}
          />
          <div style={{ fontSize: "14px", fontWeight: "bold" }}>User Details</div>
          <div className="row">
            <div className="col-lg-3 col-1" />

            <div className="col-lg-6 col-10 px-5">

              <div className="label-form">Student Name.</div>
              <input
                className="col-12  input-style"
                type="text"
                value={Name}
                onChange={(event) => { setName(event.target.value) }}
                placeholder="Enter Your Name"
                required
              /><br />
              <div className="label-form">Phone Number.</div>
              <input
                className="col-12  input-style"
                type={"text"}
                value={PhoneNumber}
                onChange={(event) => { setPhoneNumer(event.target.value) }}
                placeholder="Enter Phone Number"
                required
              /><br />
              <button onClick={() => {
                localStorage.setItem('studentName', Name);
                localStorage.setItem('studentPhone', PhoneNumber);
                setChoose(1)
                setTimingStarted(true)
                setStart(true)
              }} className="sign-in-button-1 col-8">
                Start Exam
              </button>
              <br />
            </div>
          </div>
        </div>
        :
        <>
          {choose == 0 &&
            <div style={{ display: 'flex', justifyContent: 'center', padding: "30px" }}>
              <div style={{ width: "100%" }}>
                <div style={{ textAlign: 'center' }}>
                  <h1>{ExamData.title.rendered}</h1>
                  <br />
                  {LoginUser.userPhoto === undefined ?
                    <img src={UserPlaceHolder}
                      height="30px"
                    />
                    :
                    <img src={`${LoginUser.userPhoto}`} className={"height-width-150 border border-dark"} />
                  }
                </div>
                <div>
                  <br />
                  <b>Name of Student: {LoginUser.userName}</b>
                  <br />
                  <b>Student Email: {LoginUser.userEmail}</b>
                  <br />
                </div>
                <div>
                  <b>Exam description</b>
                  <br />
                  <div dangerouslySetInnerHTML={{ __html: ExamData.meta.exam_details }} />
                </div>
                <br />
                <div className="row">
                  <div className="col-lg-4 col-12" />
                  <div className="col-lg-4 col-12 center">
                    <button className="sign-in-button-11 col-lg-8 col-7"
                      onClick={() => {
                        setTimingStarted(true)
                        setChoose(1)
                      }}
                    >
                      Get Started
                    </button>
                    <button className="sign-in-button-4 col-lg-6 col-6"
                      onClick={() => {
                        history.goBack()
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }

          {choose == 1 &&
            <div>
              <SecondScreenComponent
                QuestionsEditKey={QuestionsEditKey} setQuestionsEditKey={setQuestionsEditKey}
                QuestionsEditData={QuestionsEditData} setQuestionsEditData={setQuestionsEditData}
                QuestionsData={QuestionsData} setQuestionsData={setQuestionsData}
                QuestionsEditMode={QuestionsEditMode} setQuestionsEditMode={setQuestionsEditMode}
                TimingStarted={TimingStarted} setTimingStarted={setTimingStarted}
                ExamData={ExamData}
                AnswerArray={AnswerArray}
                setChoose={setChoose}
                SubmitExam={SubmitExam}
                mediaPlayedJSON={mediaPlayedJSON}
                PlaySingle={PlaySingle}
              />
            </div>
          }
          {choose == 2 &&
            <div>
              <FinalScreenExam
                ExamData={ExamData}
                QuestionsData={QuestionsData}
                AnswerArray={AnswerArray}
                userDetails={userDetails}
                ResultData={ResultData}
              />
            </div>
          }
        </>
      }
    </div>
  )
}
