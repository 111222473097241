import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import { CSVLink } from "react-csv";


export default function AllWeeklyExamResults() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [StudentsList, setStudentsList] = React.useState([])
  const [StudentsList1, setStudentsList1] = React.useState([])
  const [ExamList, setExamList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [AllTotal, setAllTotal] = React.useState(0)
  const [serachStudent, setSearchStudent] = React.useState("")
  const [serachExam, setSearchExam] = React.useState("")
  const [serachStudentDate, setSearchStudentDate] = React.useState("")
  const [serachExamID, setSearchExamID] = React.useState("")
  const [SubscriberTotal, setSubscriberTotal] = React.useState(0)
  const [StudentTotal, setStudentTotal] = React.useState(0)
  const [AdminTotal, setAdminTotal] = React.useState(0)
  const [ExamTotal, setExamTotal] = React.useState(0)
  const [choose, setChoose] = React.useState(0)

  const GetAllProjects = (params, setData, slug, setTotal) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slug)
      .then((res) => {
        console.log(res.data)
        setData(res.data)
        setTotal(res.headers["x-wp-total"])
        if (slug == slugs.db_slug_weekly_results)
          setCustomersList(res.data)
        if (slug == slugs.db_slug_user_data) {
          setStudentsList1(res.data)
          GetAllExam(res.data)
        }
        // CSVData(res.data, UserData)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  const GetAllExam = (UserData) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth({
      per_page: 49000,
      _fields: "id,date,title,slug,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results,meta.exam_name_results"
    }, slugs.db_slug_weekly_results)
      .then((res) => {
        function uniqueByKeepLast(data,key){
          return[
            ...new Map(
              data.map(x=>[key(x),x])
            ).values()
          ]
        }
        var resultData=uniqueByKeepLast(res.data,it=>it.title.rendered)
    
        setProjects1(resultData)
        setAllTotal(res.headers["x-wp-total"])
        setCustomersList(resultData)
        CSVData(resultData, UserData)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }

  useEffect(() => {
    // GetAllProjects({
    //   per_page: 4900,
    //   _fields: "id,title,slug,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results,meta.exam_name_results"
    // }, setProjects1, slugs.db_slug_weekly_results, setAllTotal)
    GetAllProjects({
      per_page: 500,
      _fields: "id,title,slug,meta.available_unavailable,meta.exam_type"
    }, setExamList, slugs.db_slug_weekly_exam, setExamTotal)
    GetAllProjects({
      per_page: 4900,
      _fields: "id,title,slug,meta.user_phone_number,meta.batch_name,meta.user_id"
    }, setStudentsList, slugs.db_slug_user_data, setStudentTotal)
  }, [])

  const columnsExam = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "100px"
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true,
    },
    {
      id: 33,
      name: "Availability",
      selector: (row) => row.meta.available_unavailable,
      sortable: true,
      reorder: true,
      width: "150px"
    },
    {
      id: 34,
      name: "Type",
      selector: (row) => row.meta.exam_type,
      sortable: true,
      reorder: true
    },
    {
      id: 34,
      name: "Total Exams",
      selector: (row) => CustomersList.filter(a => a.meta.exam_id_results == row.id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "Choose",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          setProjects1(CustomersList.filter(a => a.meta.exam_id_results == row.id))
          CSVData(CustomersList.filter(a => a.meta.exam_id_results == row.id), StudentsList1)
          setChoose(0)
        }}
      >
        Choose
      </div>,

    }
  ];
  const columnsStudent = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.meta.user_id,
      sortable: true,
      reorder: true,
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Phone",
      selector: (row) => row.meta.user_phone_number,
      sortable: true,
      reorder: true
    },
    {
      id: 32,
      name: "Batch",
      selector: (row) => row.meta.batch_name,
      sortable: true,
      reorder: true
    },
    {
      id: 3421,
      name: "Total Exams",
      selector: (row) => CustomersList.filter(a => a.meta.student_id_results == row.meta.user_id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 3123,
      name: "Choose",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          setProjects1(CustomersList.filter(a => a.meta.student_id_results == row.meta.user_id))
          CSVData(CustomersList.filter(a => a.meta.student_id_results == row.meta.user_id), StudentsList1)
          setChoose(0)
        }}
      >
        Choose
      </div>,

    }
  ];
  const columns = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
    },
    {
      id: 31,
      name: "Exam",
      selector: (row) => row.meta.exam_name_results,
      sortable: true,
      reorder: true
    },
    {
      id: 313,
      name: "Student id",
      // selector: (row) => row.meta.student_id_results,
      selector: (row) => StudentsList.filter(a => a.meta.user_id == row.meta.student_id_results).length !== 0 ?
        StudentsList.filter(a => a.meta.user_id == row.meta.student_id_results)[0].title.rendered : "",
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Exam id",
      selector: (row) => row.meta.exam_id_results,
      sortable: true,
      reorder: true
    },
    {
      id: 314,
      name: "Total",
      selector: (row) => row.meta.exam_total_results,
      sortable: true,
      reorder: true
    },
    {
      id: 315,
      name: "Date",
      selector: (row) => row.meta.exam_date_results,
      sortable: true,
      reorder: true
    },
    {
      id: 32,
      name: "Delete",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          AdsPUTApi({
            status: "draft"
          }, slugs.db_slug_weekly_results, row.id)
            .then((res) => {
              setSearchStudent("")
              setSearchExam("")
              setSearchExamID("")
              GetAllProjects({
                per_page: 4900,
                _fields: "id,title,slug,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results,meta.exam_name_results"
              }, setProjects1, slugs.db_slug_weekly_results, setAllTotal)

            })
            .catch((err) => {
              console.log(err)
            })
        }}
      >Delete</div>,

    },
    {
      id: 32,
      name: "View",
      right: true,
      cell: (row) => <Link to={`${slugs.result_admin_view}/${row.slug}`}>View</Link>,

    }
  ];

  const date = new Date();
  const CSVData = (ArrayData, UserData) => {
    var dData = []
    for (let i = 0; i < ArrayData.length; i++) {
      var row = ArrayData[i]
      console.log(UserData.filter(a => a.meta.user_id == row.meta.student_id_results))
      dData.push({
        id: row.id,
        name: row.meta.exam_name_results,
        student_id: row.meta.student_id_results,
        student_name:
          UserData.filter(a => a.meta.user_id == row.meta.student_id_results).length !== 0 ?
            UserData.filter(a => a.meta.user_id == row.meta.student_id_results)[0].title.rendered : "",
        marks_obtained: row.meta.exam_total_results,
        date: row.meta.exam_date_results
      })
    }
    setCustomersDownload(dData)
  }

  const [CustomersDownload, setCustomersDownload] = React.useState([])
  const headers = [
    { label: "Exam Name", key: "name" },
    { label: "Student Name", key: "student_name" },
    { label: "Student id", key: "student_id" },
    { label: "Total Obtained", key: "marks_obtained" },
    { label: "Date", key: "date" },
  ];
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Weekly Exam Results</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Weekly Exams
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div style={{ display: "inline", cursor: "pointer" }}>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(0)
                setProjects1(CustomersList)
                CSVData(CustomersList, StudentsList1)
              }}
            >
              All ({AllTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(1)
              }}
            >
              Exams ({ExamTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(2)
              }}
            >
              Students ({StudentTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList)
              }}
            >
              Clear |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}>
              <CSVLink data={CustomersDownload} headers={headers} className="Link"
                filename={`chapterResults-${date.toLocaleString()}.csv`}
              >
                Download CSV
              </CSVLink>
            </div>
          </div>
        </div>
        <div className="col-md-6 row">
          <div className="col-2" />
          <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachExam} placeholder="Exam Name"
            onChange={(event) => {
                setSearchExam(event.target.value)
                const sortValue = CustomersList.filter(a => a.meta.exam_name_results.includes(event.target.value))
              setProjects1(sortValue)
              CSVData(sortValue, StudentsList1) }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachExamID} placeholder="Exam ID"
            onChange={(event) => {
                setSearchExamID(event.target.value)
                const sortValue = CustomersList.filter(a => a.meta.exam_id_results.includes(event.target.value))
                setProjects1(sortValue)
                CSVData(sortValue, StudentsList1)
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachStudent} placeholder="Student ID"
            onChange={(event) => {
              setSearchStudent(event.target.value)
              const sortValue = CustomersList.filter(a => a.meta.student_id_results.includes(event.target.value))
              setProjects1(sortValue)
              CSVData(sortValue, StudentsList1)
           }}
          />
             <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
              value={serachStudentDate} placeholder="Date" type="date"
              onChange={(event) => {
                setSearchStudentDate(event.target.value)
                const sortValue = CustomersList.filter(a => a.date.slice(0, 10).includes(event.target.value))
                setProjects1(sortValue)
                CSVData(sortValue, StudentsList1)
              }}
            />
        </div>
      </div>


      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        {choose == 0 &&
          <DataTable
            columns={columns}
            data={Projects1}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
        {choose == 1 &&
          <DataTable
            columns={columnsExam}
            data={ExamList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
        {choose == 2 &&
          <DataTable
            columns={columnsStudent}
            data={StudentsList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}