import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { slugs } from '../../../Constant/slugs';
import './styles.css'
import SecondResultScreenComponent from '../ExamResult/SecondScreen';
export default function FinalScreenExam(props) {
  const {
    ExamData,
    QuestionsData,
    AnswerArray,
    userDetails,
    ResultData
  } = props;
  const [totalAttempt, setTotalAttempt] = React.useState(0)
  const [correctAnswer, setCorrectAnswer] = React.useState(0)
  const location = useLocation()

  const [QuestionsSolved, setQuestionsSolved] = React.useState(0);
  const [QuestionsEditMode1, setQuestionsEditMode1] = React.useState(false);
  const [ArrayIndex, setArrayIndex] = React.useState("");
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(true);
  const [QuestionsEditData, setQuestionsEditData] = React.useState(
    JSON.stringify(location).includes(slugs.start_demo_exam)||JSON.stringify(location).includes(slugs.start_weekly_exam) ?
      JSON.parse(ExamData.meta.exam_question_text)["item-0"] :
      JSON.parse(ExamData.meta.exam_question_block_text)["item-0"]
  );
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState("item-0");

  useEffect(() => {
    var attempt = 0;
    var correct = 0;
    for (let i = 0; i < AnswerArray.length; i++) {
      if (AnswerArray[i].solved == 1)
        attempt++;
      if (AnswerArray[i].correct_answer == AnswerArray[i].answerChoosen)
        correct++
      setTotalAttempt(attempt);
      setCorrectAnswer(correct)
    }
  }, [])
  return (
    <div style={{ padding: "20px" }}>
      <h2>Congratulations! </h2>
      <br />
      <br />
      <h1>{ExamData.title.rendered}</h1>
      {/* {JSON.stringify(ResultData)} */}
      {ExamData.meta.exam_code == "" ?
        <div>
          <h4>Your Result ID is : {ResultData.id}</h4>
          <span>Please check your result in our official facebook page</span>
        </div>
        :
        <>
          <div style={{ padding: "10px" }}>
            <span style={{
              border: "2px solid black",
              margin: "10px", width: "20px", height: "20px", backgroundColor: "#66fc03"
            }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Correct_Answer
            <span style={{
              border: "2px solid black",
              margin: "10px", width: "20px", height: "20px", backgroundColor: "#f00"
            }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Wrong_Answer
            <span style={{
              border: "2px solid black",
              margin: "10px", width: "20px", height: "20px", backgroundColor: "#f7e686"
            }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Unsolved_Question

          </div>
          {QuestionsEditMode ?
            <>
              <div className="row center row-data">
                <div className="col-md-6 row center" >
                  {Object.keys(QuestionsData).map((item, index) => {
                    if (index < 20)
                      return (
                        <div key={index} className="col-2 m-1 p-2 center"
                          style={{
                            backgroundColor: !AnswerArray[index].solved == 1 ?
                              "#f7e686" :
                              AnswerArray[index].correct_answer == AnswerArray[index].answerChoosen ?
                                "#66fc03" :
                                "#f00"
                            ,
                            fontWeight: QuestionsEditKey == item ? "bold" : "normal",
                            border: "2px solid black",
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setQuestionsEditData(QuestionsData[item])
                            setQuestionsEditKey(item)
                            setQuestionsEditMode(false)
                            setArrayIndex(index)
                          }}
                        >
                          {index+1}
                          {/* {QuestionsData[item].question_number} */}
                        </div>
                      )
                  })}
                </div>
                <div className="col-md-6 row">
                  {Object.keys(QuestionsData).map((item, index) => {
                    if (index >= 20)
                      return (
                        <div key={index} className="col-2 m-1 p-2 center"
                          style={{
                            backgroundColor: !AnswerArray[index].solved == 1 ?
                              "#f7e686" :
                              AnswerArray[index].correct_answer == AnswerArray[index].answerChoosen ?
                                "#66fc03" :
                                "#f00"
                            ,
                            fontWeight: QuestionsEditKey == item ? "bold" : "normal",
                            border: "2px solid black",
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setQuestionsEditData(QuestionsData[item])
                            setQuestionsEditKey(item)
                            setQuestionsEditMode(false)
                            setArrayIndex(index)
                          }}
                        >
                          {index+1}
                          {/* {QuestionsData[item].question_number} */}
                        </div>
                      )
                  })}
                </div>
              </div>
              <div className="row row-data">
                <div className="col-8">Total Questions</div>
                <div className="col-4">{Object.keys(QuestionsData).length}</div>
              </div>
              <div className="row row-data">
                <div className="col-8">Total Attempt</div>
                <div className="col-4">{totalAttempt}</div>
              </div>
              <div className="row row-data">
                <div className="col-8">Total Correct</div>
                <div className="col-4">{correctAnswer}</div>
              </div>
              <div className="row row-data">
                <div className="col-8">Total Marks Obtained</div>
                <div className="col-4">{correctAnswer * 5}</div>
              </div>
              <div className="row row-data">
                <div className="col-8">Percentage Obtained</div>
                <div className="col-4">{(correctAnswer / AnswerArray.length * 100).toFixed(2)}%</div>
              </div>
              <div style={{ fontSize: "12px" }}> (each question carries 5 marks)
              </div>
            </>
            :
            <>
              {!QuestionsEditMode1 ?
                <SecondResultScreenComponent
                  keyValue={QuestionsEditKey}
                  oneQuestionData={QuestionsEditData}
                  AllQuestionData={QuestionsData}
                  AnswerArray={AnswerArray}
                  ArrayIndex={ArrayIndex}
                  setQuestionsSolved={setQuestionsSolved}
                  QuestionsSolved={QuestionsSolved}
                />
                :
                <div style={{ minHeight: "50vh" }}>
                </div>
              }
              <div className="row mx-5 mt-3">
                <div className="col-md-4">
                  {ArrayIndex !== 0 &&
                    <div className="col-12 previous-question" style={{ cursor: "pointer" }}
                      onClick={() => {
                        setQuestionsEditMode1(true)
                        setTimeout(() => {
                          setQuestionsEditMode1(false)
                        }, 0)
                        setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex - 1]])
                        setQuestionsEditKey(Object.keys(QuestionsData)[ArrayIndex - 1])
                        setArrayIndex(ArrayIndex - 1)
                      }}
                    >
                      <button className="sign-in-button-1 shadow">
                        <i className="fa fa-chevron-left" />&nbsp; Previous Question
                      </button>
                    </div>
                  }
                </div>
                <div className="col-md-4 " style={{ textAlign: 'center', cursor: "pointer" }}
                  onClick={() => {
                    setQuestionsEditMode(true)
                  }}
                >
                  <button className="sign-in-button-1 shadow">
                    <i className="fa fa-list" />&nbsp;   Question List
                  </button>
                </div>
                {ArrayIndex !== Object.keys(QuestionsData).length - 1 &&
                  <div className="col-md-4 question-list" style={{ cursor: "pointer" }}
                    onClick={() => {
                      setQuestionsEditMode1(true)
                      setTimeout(() => {
                        setQuestionsEditMode1(false)
                      }, 0)
                      setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex + 1]])
                      setQuestionsEditKey(Object.keys(QuestionsData)[ArrayIndex + 1])
                      setArrayIndex(ArrayIndex + 1)
                    }
                    }
                  >
                    <button className="sign-in-button-1 shadow">
                      Next Question&nbsp;  <i className="fa fa-chevron-right" />
                    </button>
                  </div>

                }
              </div>
            </>

          }
        </>
      }

      <div className="row center">
        <Link to={"/"} className="col-lg-2 col-12 Link">

          <div className="col-12 sign-in-button-1">
            All Exam
          </div>
        </Link>
      </div>
    </div>
  )
}