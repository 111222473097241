import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { logout } from '../../../redux/action';

export default function AllWeeklyExamList() {
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [reload, setReload] = React.useState(false)
  const [pagesLength, setPagesLength] = React.useState("1");
  const [pages, setPages] = React.useState(1);
  const dispatch = useDispatch()

  const fetchExam = (setAllExamList, perPage) => {
    setReload(true)
    AdsGETApiAuth({
      ...perPage,
    }, slugs.db_slug_weekly_exam)
      .then((res) => {
        setAllExamList(res.data)
        setReload(false)
        console.log(res.data.length)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        if (err.message.includes("Request failed with status code 500"))
          dispatch(logout())
      })
  }
  const location = useLocation()
  useEffect(() => {
    fetchExam(setAllExamList1, {
      per_page: 100, order: "asc",
      _fields: "id,title,slug,meta.exam_code,meta.available_unavailable,meta.exam_question_block_student,meta.featured_image",
      "filter[meta_query][1][key]": "available_unavailable",
      "filter[meta_query][1][value][0]": "Available",
      "filter[meta_query][1][compare]": "=",
      "filter[meta_query][relation]": "AND",


    })
    console.log(location.pathname.slice(11))
  }, [])

  return (
    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mt-3">
          <div className="row" style={{ margin: "10px" }}>
            <h2>All Weekly Exams</h2>
            {AllExamList1.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center" style={{ padding: "0 5px" }}>
                <ExamBlock item={item} examSlug={slugs.start_weekly_exam} />
              </div>
            ))}
            {AllExamList1.length == 0 &&
              <div style={{ padding: '10px' }}>
                No Weekly Exam Available Right now
              </div>
            }
          </div>
        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}

const ExamBlock = ({ item, examSlug }) => {
  const location = useLocation();
  const [Code, setCode] = React.useState("")
  const [StartPressed, setStartPressed] = React.useState(false)
  return (
    <div style={{
      padding: "5px", margin: "5px 0px", border: "1.5px solid #bbb",
      borderRadius: "5px",
    }}>
      {item.meta.featured_image !== "" ?
        <img className="col-12" src={item.meta.featured_image}
          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
        :
        <img className="col-12"
          src={"https://damakhorizon.com/logo.jpeg"}
          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
      }
      <br />
      <div>
        {item.title.rendered}&nbsp;
      </div>
      {(!JSON.stringify(location).includes("all-demo-exam-list") && item.meta.exam_question_block_student == undefined) ?
        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
          <div className="sign-in-button-1 col-8 Link" style={{ color: "#fff", opacity: 0.5 }}
          >
            Verify to Start
          </div>
        </div>
        :
        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
          {item.meta.available_unavailable == "Available" ?
            <>
              {item.meta.exam_code == "" ?
                <Link className="sign-in-button-1 col-8 Link"
                  to={`${examSlug}/${item.slug}`}
                >
                  <div style={{ color: "#fff" }}>
                    Start Exam
                  </div>
                </Link>
                :
                <div className="sign-in-button-1 col-8 "
                  onClick={() => { setStartPressed(true) }}
                >
                  <div style={{ color: "#fff" }}>
                    Exam Code
                  </div>
                </div>
              }
            </>
            :
            <div className="sign-in-button-1 col-8 Link" style={{ color: "#000", backgroundColor: "#fff" }}>
              Exam Unavailable
            </div>
          }
        </div >
      }
      {
        StartPressed &&
        <div className="modal">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Exam Code</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                  onClick={() => { setStartPressed(false) }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Please Enter The Weekly Exam Code to start with your exam.</p>
                <input className="input-common"
                  value={Code} placeholder="Exam Code"
                  onChange={(event) => { setCode(event.target.value) }}
                />
              </div>
              <div class="modal-footer">
                {Code == item.meta.exam_code ?
                  <Link to={`${examSlug}/${item.slug}`}>
                    <button type="button" class="btn btn-primary"
                      onClick={() => {
                        localStorage.setItem("weekly_exam_code", Code)
                      }}
                    >Start Exam</button>
                  </Link>
                  :
                  <div type="button" class="btn btn-dark">Start Exam</div>
                }
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                  onClick={() => { setStartPressed(false) }}
                >Cancel</button>
              </div>
            </div>
          </div>
        </div>
      }
    </div >
  )

}